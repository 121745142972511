import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './faq.css'

const FAQ = (props) => {
  const [faq3Visible, setFaq3Visible] = useState(false)
  const [faq1Visible, setFaq1Visible] = useState(false)
  const [faq9Visible, setFaq9Visible] = useState(false)
  const [faq7Visible, setFaq7Visible] = useState(false)
  const [faq8Visible, setFaq8Visible] = useState(false)
  const [faq4Visible, setFaq4Visible] = useState(false)
  const [faq10Visible, setFaq10Visible] = useState(false)
  const [faq5Visible, setFaq5Visible] = useState(false)
  const [faq2Visible, setFaq2Visible] = useState(false)
  const [faq6Visible, setFaq6Visible] = useState(false)
  return (
    <div className={`faqfaq8 thq-section-padding ${props.rootClassName} `}>
      <div className="faq-max-width thq-section-max-width">
        <div className="faq-container10 thq-flex-column thq-section-max-width">
          <div className="faq-section-title">
            <div className="faq-content">
              <p className="faq-text10 thq-body-large"></p>
            </div>
          </div>
          <h2 className="faq-text11 thq-heading-2">{props.faqhead}</h2>
          <div className="faq-list">
            <div className="faq-faq1">
              <div
                onClick={() => setFaq1Visible(!faq1Visible)}
                className="faq-trigger10"
              >
                <p className="faq-faq1-question1 thq-body-large">
                  {props.faq1Question}
                </p>
                <div className="faq-icons-container10">
                  {faq1Visible === false && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon10">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq1Visible === true && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon12">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq1Visible === true && (
                <div className="faq-container13">
                  <span className="faq-text12 thq-body-small">
                    <span className="faq-text13">Mon </span>
                    <span className="faq-text14">CLOSED</span>
                    <br className="faq-text15"></br>
                    <span className="faq-text16">Tue </span>
                    <span className="faq-text17">CLOSED</span>
                    <br className="faq-text18"></br>
                    <span className="faq-text19">Wed 8am - 3pm</span>
                    <br className="faq-text20"></br>
                    <span className="faq-text21">Thu 8am - 3pm</span>
                    <br className="faq-text22"></br>
                    <span className="faq-text23">Fri 8am - 9pm</span>
                    <br className="faq-text24"></br>
                    <span className="faq-text25">Sat 8am - 9pm</span>
                    <br className="faq-text26"></br>
                    <span className="faq-text27">Sun 8am - 6pm</span>
                    <br></br>
                  </span>
                </div>
              )}
            </div>
            <div className="faq-faq2">
              <div
                onClick={() => setFaq2Visible(!faq2Visible)}
                className="faq-trigger11"
              >
                <p className="faq-faq2-question1 thq-body-large">
                  {props.faq2Question}
                </p>
                <div className="faq-icons-container11">
                  {!faq2Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon14">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq2Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon16">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq2Visible && (
                <div className="faq-container16">
                  <span className="faq-text29 thq-body-small">
                    <span>
                      We offer share plates, salads and pizza
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <a
                      href="/public/main_menu.pdf"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      CLICK HERE
                    </a>
                    <span> to view the menu</span>
                  </span>
                </div>
              )}
            </div>
            <div className="faq-faq3">
              <div
                onClick={() => setFaq3Visible(!faq3Visible)}
                className="faq-trigger12"
              >
                <p className="faq-faq2-question2 thq-body-large">
                  {props.faq3Question}
                </p>
                <div className="faq-icons-container12">
                  {!faq3Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon18">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq3Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon20">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq3Visible && (
                <div className="faq-container19">
                  <span className="faq-text32 thq-body-small">
                    Use the Book Now button above
                  </span>
                </div>
              )}
            </div>
            <div className="faq-faq4">
              <div
                onClick={() => setFaq4Visible(!faq4Visible)}
                className="faq-trigger13"
              >
                <p className="faq-faq2-question3 thq-body-large">
                  {props.faq4Question}
                </p>
                <div className="faq-icons-container13">
                  {!faq4Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon22">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq4Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon24">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq4Visible && (
                <div className="faq-container22">
                  <span className="faq-text33 thq-body-small">
                    Yes, we are. From 8am - 4pm
                  </span>
                </div>
              )}
            </div>
            <div className="faq-faq5">
              <div
                onClick={() => setFaq5Visible(!faq5Visible)}
                className="faq-trigger14"
              >
                <p className="faq-faq1-question2 thq-body-large">
                  {props.faq5Question}
                </p>
                <div className="faq-icons-container14">
                  {!faq5Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon26">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq5Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon28">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq5Visible && (
                <div className="faq-container25">
                  <span className="faq-text34 thq-body-small">
                    Walk-ins are welcome
                  </span>
                </div>
              )}
            </div>
            <div className="faq-faq6">
              <div
                onClick={() => setFaq6Visible(!faq6Visible)}
                className="faq-trigger15"
              >
                <p className="faq-faq1-question3 thq-body-large">
                  {props.faq6Question}
                </p>
                <div className="faq-icons-container15">
                  {!faq6Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon30">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq6Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon32">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq6Visible && (
                <div className="faq-container28">
                  <span className="faq-text35 thq-body-small">
                    We are a fully licensed venue. Sorry, no BYO
                  </span>
                </div>
              )}
            </div>
            <div className="faq-faq7">
              <div
                onClick={() => setFaq7Visible(!faq7Visible)}
                className="faq-trigger16"
              >
                <p className="faq-faq1-question4 thq-body-large">
                  {props.faq7Question}
                </p>
                <div className="faq-icons-container16">
                  {!faq7Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon34">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq7Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon36">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq7Visible && (
                <div className="faq-container31">
                  <span className="faq-text36 thq-body-small">
                    We have ample undercover/shaded outdoor seating
                  </span>
                </div>
              )}
            </div>
            <div className="faq-faq8">
              <div
                onClick={() => setFaq8Visible(!faq8Visible)}
                className="faq-trigger17"
              >
                <p className="faq-faq1-question5 thq-body-large">
                  {props.faq8Question}
                </p>
                <div className="faq-icons-container17">
                  {!faq8Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon38">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq8Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon40">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq8Visible && (
                <div className="faq-container34">
                  <span className="faq-text37 thq-body-small">
                    We recommend several weeks in advance for bookings in excess
                    of 15 guests
                  </span>
                </div>
              )}
            </div>
            <div className="faq-faq9">
              <div
                onClick={() => setFaq9Visible(!faq9Visible)}
                className="faq-trigger18"
              >
                <p className="faq-faq1-question6 thq-body-large">
                  {props.faq9Question}
                </p>
                <div className="faq-icons-container18">
                  {!faq9Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon42">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq9Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon44">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq9Visible === true && (
                <div className="faq-container37">
                  <span className="faq-text38 thq-body-small">
                    <span>
                      Yes, we have a seperate function space which caters for up
                      to 120 guests. 
                    </span>
                    <a
                      href="https://swanvalleyhotel.com.au/weddings-functions-events/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="faq-link2"
                    >
                      Please get in touch
                    </a>
                    <span> </span>
                  </span>
                </div>
              )}
            </div>
            <div className="faq-faq10">
              <div
                onClick={() => setFaq10Visible(!faq10Visible)}
                className="faq-trigger19"
              >
                <p className="faq-faq1-question7 thq-body-large">
                  {props.faq10Question}
                </p>
                <div className="faq-icons-container19">
                  {!faq10Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon46">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq10Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon48">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq10Visible && (
                <div className="faq-container40">
                  <span className="faq-text41 thq-body-small">
                    Yes, walk-ins are welcome
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

FAQ.defaultProps = {
  faq5Question: 'Can I make a last-minute booking?',
  question: 'Still have a question?',
  faq8Question: 'How far in advance do I need to book for a large group?',
  faq4Question: 'Are you open on public holidays?',
  faq2Question: 'What kind of food do you serve?',
  faqhead: 'F.A.Q.',
  faq3Question: 'How do I book a table?',
  faq7Question: 'Do you have outdoor seating available?',
  faq6Question: 'Are you licensed or BYO – can I bring my own wine and beer?',
  faq9Question: 'Do you cater for functions?',
  faq10Question: 'Are walk-ins welcome?',
  faq1Question: 'What are the opening hours?',
  contact: 'Contact',
  rootClassName: '',
}

FAQ.propTypes = {
  faq5Question: PropTypes.string,
  question: PropTypes.string,
  faq8Question: PropTypes.string,
  faq4Question: PropTypes.string,
  faq2Question: PropTypes.string,
  faqhead: PropTypes.string,
  faq3Question: PropTypes.string,
  faq7Question: PropTypes.string,
  faq6Question: PropTypes.string,
  faq9Question: PropTypes.string,
  faq10Question: PropTypes.string,
  faq1Question: PropTypes.string,
  contact: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default FAQ
