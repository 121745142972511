import React from 'react'

import { Helmet } from 'react-helmet'

import FAQ from '../components/faq'
import './gerrys.css'

const Gerrys = (props) => {
  return (
    <div className="gerrys-container1">
      <Helmet>
        <title>Gerry's</title>
        <meta
          name="description"
          content="Welcome to Gerry's, a New York inspired Deli, Wine Bar and Pizzeria"
        />
        <meta property="og:title" content="Gerry's" />
        <meta
          property="og:description"
          content="Welcome to Gerry's, a New York inspired Deli, Wine Bar and Pizzeria"
        />
      </Helmet>
      <img alt="image" src="/gerrys_logo-200h.png" className="gerrys-logo" />
      <span className="gerrys-address">
        <span>10250 West Swan Road</span>
        <br></br>
        <span>Henley Brook, WA 6055</span>
      </span>
      <span className="gerrys-blurb">Stay tuned for more info...</span>
      <div className="gerrys-images">
        <div className="gerrys-social-links">
          <div className="gerrys-container2">
            <div className="gerrys-sidebyside-image">
              <a
                href="https://www.instagram.com/gerrysdeliswanvalley/"
                target="_blank"
                rel="noreferrer noopener"
                className="gerrys-link1"
              >
                <img
                  alt="image"
                  src="/instagram_icon-200h.png"
                  className="gerrys-image1"
                />
              </a>
              <a
                href="https://www.facebook.com/gerrysdeliswanvalley"
                target="_blank"
                rel="noreferrer noopener"
                className="gerrys-link2"
              >
                <img
                  alt="image"
                  src="/facebook-200h.png"
                  className="gerrys-image2"
                />
              </a>
              <a
                href="https://maps.app.goo.gl/5Jj8YhDNYDAQ7R8LA"
                target="_blank"
                rel="noreferrer noopener"
                className="gerrys-link3"
              >
                <img
                  alt="image"
                  src="/google-200h.jpeg"
                  className="gerrys-image3"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="gerrys-book-now">
        <a
          href="https://bookings.nowbookit.com/?accountid=3cd4f653-08fb-41ba-a831-59fd136aa384&amp;venueid=12131&amp;theme=light&amp;colors=hex,000000"
          target="_blank"
          rel="noreferrer noopener"
          className="gerrys-distributor1 button"
        >
          <span className="gerrys-text13">Book Now</span>
        </a>
      </div>
      <div className="gerrys-buttons">
        <a
          href="/eat_drink.pdf"
          target="_blank"
          rel="noreferrer noopener"
          className="gerrys-distributor2 button"
        >
          <span className="gerrys-text14">
            <span>Main </span>
            <span>Menu</span>
          </span>
        </a>
        <a
          href="/brunch_menu.pdf"
          target="_blank"
          rel="noreferrer noopener"
          className="gerrys-distributor3 button"
        >
          <span className="gerrys-text17">Brunch Menu</span>
        </a>
      </div>
      <div className="gerrys-buttons-r21">
        <a
          href="/grab_go.pdf"
          target="_blank"
          rel="noreferrer noopener"
          className="gerrys-distributor4 button"
        >
          <span className="gerrys-text18">Grab &amp; Go</span>
        </a>
        <a
          href="https://www.meandu.app/swan-valley-hotel"
          target="_blank"
          rel="noreferrer noopener"
          className="gerrys-distributor5 button"
        >
          <span className="gerrys-text19">Digital Menu (With photos)</span>
        </a>
      </div>
      <div className="gerrys-buttons-r22">
        <a
          href="https://swanvalleyhotel.com.au/weddings-functions-events/"
          target="_blank"
          rel="noreferrer noopener"
          className="gerrys-distributor6 button"
        >
          <span className="gerrys-text20">Functions &amp; Events</span>
        </a>
      </div>
      <div className="gerrys-faq">
        <FAQ rootClassName="fa-qroot-class-name"></FAQ>
      </div>
    </div>
  )
}

export default Gerrys
